import { styled } from '@mui/material/styles';

export const CustomList = styled('ol')({
  counterReset: 'section',
  listStyleType: 'none',
  '& .list-item::before': {
    counterIncrement: 'section',
    content: 'counters(section, ".") ". "',
    fontSize: 'inherit',
  },
});
