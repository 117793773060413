import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  editableFileIdAtom,
  majorVersionAtom,
  projectIdAtom,
} from '../../DocumentationPageStates';
import { DocumentationResultQuery } from '../../../../__generated__/gql/graphql';
import VersionSelector from '../../../../components/VersionSelector';
import { useState } from 'react';
import { DocumentationResultViewer } from './DocumentationResultViewer';
import { LoadingButton } from '@mui/lab';
import { colorThemes } from '../../../../theme';
import { Autorenew } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { Loading } from '../../../../components/Loading';
import { useParams } from 'react-router-dom';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { NestedMenuItem } from 'mui-nested-menu';

const GET_DOCUMENTATION = gql(`
    query documentationResult($documentation_id: String!, $experiment_id: String, $position: Int) {
      documentation(id: $documentation_id) {
        latestVersion
        documentationResult(experimentId: $experiment_id, position: $position) {
          id
          creationTimestamp
          editableFile {
              id
          }
          generatedDocumentation {
            name
            text
            outputFormat
            artifacts {
              id
              url
              ansiText
              contentType
              creationTimestamp
              name
              tags
              fullPath
              size
              html
            }
          }
        }
      }
    }
`);

const GENERATE_DOCUMENTATION_MUTATION = gql(`
    mutation GenerateDocumentation($projectId: String!, $documentationId: String!, $experimentId: String) {
      generateDocumentationResult(projectId: $projectId, documentationId: $documentationId, experimentId: $experimentId) {
        documentationResult {
          id,
          generatedDocumentation {
            name,
            text,
            outputFormat,
            artifacts{
              id
              url
              contentType
              creationTimestamp
              name
              tags
              fullPath
              size
              html
              ansiText
            }
          }
        }
      }
    }
  `);

const EXPORT_DOCUMENTATION_TO_CONFLUENCE_MUTATION = gql(`
    mutation ExportDocumentationResultToConfluence($resultId: String!) {
      exportDocumentationResultToConfluence(resultId: $resultId) {
        link
      }
    }
  `);

export default function DocumentResultView() {
  const { documentationId } = useParams();

  const [documentationResultId, setDocumentationResultId] = useState<
    string | null
  >();

  const [versions, setVersions] = useState<number[]>([]);
  const [version, setVersion] = useState<number | null>(null);
  const setMajorVersion = useSetRecoilState(majorVersionAtom);

  const setEditableFileId = useSetRecoilState(editableFileIdAtom);
  const { data, refetch, loading } = useQuery<DocumentationResultQuery>(
    GET_DOCUMENTATION,
    {
      onCompleted: data => {
        setVersion(data.documentation?.latestVersion ?? 0);
        setVersions(
          Array.from(
            { length: data.documentation?.latestVersion ?? 1 },
            (_, i) => i + 1
          )
        );
        setMajorVersion(data.documentation?.latestVersion ?? 0);
        setDocumentationResultId(
          data.documentation?.documentationResult?.id ?? null
        );
        setEditableFileId(
          data.documentation?.documentationResult?.editableFile?.id ?? ''
        );
      },
      variables: {
        documentation_id: documentationId ?? '',
      },
      skip: !documentationId,
    }
  );

  const [generateDocumentationMutation, { loading: isGenerationLoading }] =
    useMutation(GENERATE_DOCUMENTATION_MUTATION);

  const handleVersionChange = (version: number) => {
    setVersion(version);
    refetch({
      documentation_id: documentationId,
      position: version,
    });
    setMajorVersion(version);
  };

  const projectId = useRecoilValue(projectIdAtom);

  const handleGenerateDocumentation = async () =>
    await generateDocumentationMutation({
      onCompleted: data => {
        toast.success('Documentation generated');
        const newLastVersion = (data?.documentation?.lastVersion ?? 0) + 1;
        refetch({
          documentation_id: documentationId,
        });
        setVersions(prevVersions => [...prevVersions, newLastVersion]);
        setVersion(newLastVersion);
        setMajorVersion(newLastVersion);
        setDocumentationResultId(data?.documentation?.documentationResult?.id);
      },
      onError: error => {
        toast.error(`Failed to generate documentation: ${error.message}`);
        console.error(error);
      },
      variables: {
        documentationId: documentationId!,
        projectId: projectId,
        experimentId: '',
      },
    });

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(e.currentTarget);
  };
  const handleClose = () => setMenuAnchorEl(null);
  const menuIsOpen = Boolean(menuAnchorEl);

  const [exportDocumentationToConfluence, { loading: exportingToConfluence }] =
    useMutation(EXPORT_DOCUMENTATION_TO_CONFLUENCE_MUTATION, {
      onCompleted: () => {
        toast.success('Exported to Confluence successfully!');
        handleClose();
      },
      onError: error => {
        toast.error(`Failed to export: ${error.message}`);
        handleClose();
      },
    });

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '1.5rem',
          }}
        >
          <Typography variant="h6">Generation Result</Typography>
          <Box sx={{ display: 'flex', alignContent: 'center', gap: '0.5rem' }}>
            <Typography variant="subtitle1">Version:</Typography>
            <VersionSelector
              elements={versions ?? []}
              selectedElement={version ?? 1}
              setSelectedElement={handleVersionChange}
            />
          </Box>
        </Box>
        <ButtonGroup>
          <LoadingButton
            loading={isGenerationLoading}
            loadingIndicator={<CircularProgress size={24} sx={{ mr: 1 }} />}
            onClick={() => handleGenerateDocumentation()}
            variant="contained"
            size="small"
            sx={{
              '&:hover': {
                backgroundColor: colorThemes.YELLOW_200,
              },
              '&:disabled': {
                backgroundColor: colorThemes.YELLOW_600,
              },
            }}
          >
            Regenerate
            <Autorenew
              sx={{
                fontSize: 24,
                marginLeft: '8px',
                color: colorThemes.DARK_BLUE_600,
              }}
            />
          </LoadingButton>
          <Button variant="contained" onClick={handleClick}>
            <MoreHorizIcon sx={{ color: colorThemes.DARK_BLUE_800 }} />
          </Button>
        </ButtonGroup>
        <Menu
          id="menu"
          anchorEl={menuAnchorEl}
          open={menuIsOpen}
          onClose={handleClose}
        >
          <NestedMenuItem label="Export to" parentMenuOpen={menuIsOpen}>
            <MenuItem
              disabled={exportingToConfluence}
              onClick={() => {
                exportDocumentationToConfluence({
                  variables: {
                    resultId: documentationResultId ?? '',
                  },
                });
              }}
            >
              Confluence
            </MenuItem>
          </NestedMenuItem>
        </Menu>
      </Box>
      {loading ? (
        <Loading message="Loading" />
      ) : (
        <DocumentationResultViewer
          creationTimestamp={
            data?.documentation?.documentationResult?.creationTimestamp
          }
          documentationResult={
            data?.documentation?.documentationResult?.generatedDocumentation ??
            null
          }
        />
      )}
    </Box>
  );
}
